import * as bootstrap from 'bootstrap';

export default class AgeVerificationModal {

	constructor () {
		const confirmation = localStorage.getItem('ageConfirmed');
		if (typeof confirmation == 'undefined' || confirmation == null) {
			const modalEl = document.querySelector('#ageVerificationModal');
			if (modalEl) {
				const modal = new bootstrap.Modal(modalEl, {
					backdrop: 'static',
					keyboard: false
				});

				modalEl.querySelectorAll('[data-age-verification-action]').forEach((element) => {
					element.addEventListener('click', function (e) {
						e.preventDefault();
						const action = this.dataset.ageVerificationAction;
						if (action === 'confirm') {
							localStorage.setItem('ageConfirmed', '1');
							modal.hide();
						} else if (action === 'refuse') {
							window.location.href = 'https://www.dumbum.cz';
						}
					});
				});

				modal.show();
			}
		}
	}
}