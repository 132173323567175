import * as bootstrap from 'bootstrap';
import naja from 'naja';

export class CartProductAddedModal {

	initialize(naja) {
		naja.snippetHandler.addEventListener('afterUpdate', this.afterUpdate.bind(this));

		const modalEl = document.querySelector('#cart-product-added-modal');
		this.modal = bootstrap.Modal.getOrCreateInstance(modalEl);
	}

	afterUpdate({detail}) {
		if (detail.snippet.hasAttribute('id') && detail.snippet.getAttribute('id') == 'snippet-cartProductAddedModal-content') {
			if (!this.modal._isShow) {
				this.modal.show();
			}
		}
	}

}

naja.registerExtension(new CartProductAddedModal());